import { useTranslation } from 'react-i18next'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from './ui/tooltip'

export const BlurContent = ({
	children,
	blur = true,
	blurChildren,
}: {
	children: React.ReactNode
	blur?: boolean
	blurChildren?: React.ReactNode
}) => {
	const { t } = useTranslation()
	return (
		<div className="relative">
			{blur ? (
				<TooltipProvider>
					<Tooltip>
						<TooltipContent side="top">
							<p>{t('loginToViewMore')}</p>
						</TooltipContent>
						<TooltipTrigger asChild>
							<div className="absolute flex h-full w-full items-center justify-center bg-white/30 backdrop-blur-sm">
								{blurChildren}
							</div>
						</TooltipTrigger>
					</Tooltip>
				</TooltipProvider>
			) : null}
			<div className="select-none">{children}</div>
		</div>
	)
}
